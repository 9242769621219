import UAuth from '@uauth/js'
import { UAuthConnector } from '@uauth/web3-react'
import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { initializeConnector } from '@web3-react/core'
import { GnosisSafe } from '@web3-react/gnosis-safe'
import { MetaMask } from '@web3-react/metamask'
import { Network } from '@web3-react/network'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'

import CoinbaseLogo from '@/assets/icons/walletIcons/CoinbaseLogo'
import WalletConnectLogo from '@/assets/icons/walletIcons/WalletConnectLogo'

import { CHAINS, URLS } from '@/config/web3/chains'
import { getDeprecatedInjection } from '@/connection/connectors/connectors.helper'
import { EIP6963 } from '@/connection/connectors/eip6963Connector/eip6963Connector'
import { ConnectionType } from '@/constants/web3'
import web3reactError from '@/utils/web3ReactError'

import { LedgerIFrameConnector } from './connectors/LedgerIFrameConnector/ledgerIFrameConnector'

import {
  Connection,
  InjectedConnection,
  ProviderInfo,
} from '@/types/connectors'

UAuthConnector.registerUAuth(UAuth)

const onError = (error: Error) => {
  //Necessary for now, until we have Global error context. Turn off no-console on eslintrc if issue committing
  console.debug(`web3-react error: ${error}`)
}

const [web3Network, web3NetworkHooks] = initializeConnector<Network>(
  (actions) => new Network({ actions, urlMap: URLS, defaultChainId: 1 })
)
export const networkConnection: Connection = {
  connector: web3Network,
  hooks: web3NetworkHooks,
  type: ConnectionType.NETWORK,
  shouldDisplay: () => false,
  getProviderInfo: () => ({ name: 'Network' }),
}

const [web3Injected, web3InjectedHooks] = initializeConnector<MetaMask>(
  (actions) => new MetaMask({ actions, onError })
)

export const deprecatedInjectedConnection: Connection = {
  connector: web3Injected,
  hooks: web3InjectedHooks,
  type: ConnectionType.INJECTED,
  getProviderInfo: () => getDeprecatedInjection() ?? { name: '' },
  shouldDisplay: () => true,
}

const [eip6963, eip6963hooks] = initializeConnector<EIP6963>(
  (actions) => new EIP6963({ actions, onError: web3reactError })
)
// Since eip6963 wallet are `announced` dynamically after compile-time, but web3provider required connectors to be statically defined,
// we define a static eip6963Connection object that provides access to all eip6963 wallets. The `wrap` function is used to obtain a copy
// of the connection with metadata & activation for a specific extension/provider.
export const eip6963Connection: InjectedConnection = {
  getProviderInfo: () =>
    eip6963.provider.currentProviderDetail?.info ?? { name: 'Browser Wallet' },
  selectRdns: (rdns: string) => eip6963.selectProvider(rdns),
  connector: eip6963,
  hooks: eip6963hooks,
  type: ConnectionType.EIP_6963_INJECTED,
  shouldDisplay: () => false, // Since we display each individual eip6963 wallet, we shouldn't display this generic parent connection
  wrap(providerInfo: ProviderInfo) {
    const { rdns } = providerInfo

    if (!rdns) return undefined
    return {
      ...this,
      getProviderInfo: () => providerInfo,
      overrideActivate() {
        eip6963.selectProvider(rdns) // Select the specific eip6963 provider before activating
        return false
      },
      shouldDisplay: () => true, // Individual eip6963 wallets should always be displayed
    }
  },
}

const [web3GnosisSafe, web3GnosisSafeHooks] = initializeConnector<GnosisSafe>(
  (actions) => new GnosisSafe({ actions })
)
export const gnosisSafeConnection: Connection = {
  connector: web3GnosisSafe,
  hooks: web3GnosisSafeHooks,
  type: ConnectionType.GNOSIS_SAFE,
  shouldDisplay: () => false,
  getProviderInfo: () => ({
    name: 'Gnosis Safe',
    icon: '',
  }),
}

const [web3LedgerIFrame, web3LedgerIFrameHooks] =
  initializeConnector<LedgerIFrameConnector>(
    (actions) => new LedgerIFrameConnector({ actions })
  )
export const ledgerIFrameConnection: Connection = {
  connector: web3LedgerIFrame,
  hooks: web3LedgerIFrameHooks,
  type: ConnectionType.LEDGER_IFRAME,
  shouldDisplay: () => false,
  getProviderInfo: () => ({
    name: 'Ledger iFrame',
    icon: '',
  }),
}

const [walletConnectV2, walletConnectV2hooks] =
  initializeConnector<WalletConnectV2>(
    (actions) =>
      new WalletConnectV2({
        actions,
        options: {
          projectId: '5c42aeed3e2601d7df2464a8303b7d37',
          chains: Object.keys(CHAINS).map(Number),
          showQrModal: true,
        },
      })
  )

export const walletConnectConnection: Connection = {
  connector: walletConnectV2,
  hooks: walletConnectV2hooks,
  type: ConnectionType.WALLET_CONNECT_V2,
  shouldDisplay: () => true,
  getProviderInfo: () => ({
    name: 'Wallet Connect',
    icon: WalletConnectLogo(),
  }),
}

const [web3CoinbaseWallet, web3CoinbaseWalletHooks] =
  initializeConnector<CoinbaseWallet>(
    (actions) =>
      new CoinbaseWallet({
        actions,
        options: {
          url: 'https://eth-mainnet.public.blastapi.io',
          appName: 'Spool',
          reloadOnDisconnect: false,
        },
        onError,
      })
  )

export const coinbaseWalletConnection: Connection = {
  connector: web3CoinbaseWallet,
  hooks: web3CoinbaseWalletHooks,
  type: ConnectionType.COINBASE_WALLET,
  shouldDisplay: () => true,
  getProviderInfo: () => ({
    name: 'Coinbase Wallet',
    icon: CoinbaseLogo(),
  }),
}

//TAKE NOTE: any connections added must be added to SELECTABLE_WALLETS in useOrderedConnections
