import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import {
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { memo, useEffect, useMemo } from 'react'

import useConnectionState from '@/hooks/context/useConnectionState'
import useCreateVaultState from '@/hooks/context/useCreateVaultState'

import { SmartVaultNameTableTypography } from '@/components/atoms/SmartVaultNameTableTypography'
import { mapAssetTokenIcon } from '@/components/atoms/Tokens'
import NameCellContent from '@/components/organisms/SmartVaults/NameCellContent'
import StrategiesDisabledTooltip from '@/components/organisms/Tooltips/StrategiesDisabledTooltip'

import { NOT_AVILABLE } from '@/constants'
import { mapAssetConfig } from '@/constants/assets/assets'
import { mapRiskModelConfig } from '@/constants/riskModels/riskModels'
import { strategiesDescriptions } from '@/mockdata/create-vault.json'
import { formatNumber, formatUSD } from '@/utils/formats'
import handleSelectStrategies from '@/utils/strategies/handleSelectedStrategies'

import { StrategiesDto } from '@/types/create-vault'

interface StrategiesTableRowProps {
  strategiesData: StrategiesDto
}

const StrategiesTableRow: React.FC<StrategiesTableRowProps> = ({
  strategiesData,
}) => {
  const { chain } = useConnectionState()

  const { apy, assetGroup, chainId, name, notice, riskScore, tvr } =
    strategiesData
  const { id: assetGroupId, assetGroupTokens } = assetGroup
  const { description, warning } = notice
  const info =
    strategiesDescriptions[name as keyof typeof strategiesDescriptions]

  const {
    selectedRiskModel,
    selectedStrategies,
    selectedAssetGroupId,
    setSelectedStrategies,
    setStepCompletedStatus,
    setSelectedAssetGroupId,
    setFilterByAssetGroup,
  } = useCreateVaultState()

  const riskModelsData = useMemo(() => mapRiskModelConfig(chain), [chain])
  const riskModelName = useMemo(
    () => riskModelsData[selectedRiskModel]?.riskModelName,
    [riskModelsData, chain, selectedRiskModel]
  )

  const isSelected = useMemo(
    () =>
      selectedStrategies.some(
        (selectedStrategy) => selectedStrategy.name === strategiesData.name
      ),
    [selectedStrategies, strategiesData]
  )

  useEffect(() => {
    const isCompleted = selectedStrategies.length > 0
    setStepCompletedStatus(2, isCompleted)

    setSelectedAssetGroupId(
      isCompleted ? selectedStrategies[0].assetGroup.id : ''
    )
  }, [selectedStrategies])

  const onRowClick = () => {
    if (!selectedAssetGroupId || selectedAssetGroupId === assetGroupId) {
      handleSelectStrategies(
        strategiesData,
        selectedStrategies,
        setFilterByAssetGroup,
        setSelectedStrategies
      )
    }
  }

  const assetConfig = useMemo(() => mapAssetConfig(chain), [chain])

  return (
    <>
      <Tooltip
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -8],
              },
            },
          ],
        }}
        disableHoverListener={
          !selectedAssetGroupId || selectedAssetGroupId === assetGroupId
        }
        arrow
        title={<StrategiesDisabledTooltip />}
      >
        <TableRow
          hover={chain === chainId}
          role='checkbox'
          tabIndex={-1}
          selected={isSelected}
          sx={{
            '& > td': {
              borderBottom: 'unset',
            },
            opacity:
              selectedAssetGroupId && selectedAssetGroupId !== assetGroupId
                ? '0.4'
                : '1',
            backgroundColor: '#fff',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            userSelect: 'none',
          }}
          onClick={
            selectedAssetGroupId && selectedAssetGroupId !== assetGroupId
              ? undefined
              : onRowClick
          }
        >
          <TableCell padding='none' sx={{ minWidth: '0px' }}>
            <Checkbox
              color='primary'
              checked={isSelected}
              sx={{ paddingRight: '2px' }}
              disableRipple
            />
          </TableCell>
          <TableCell>
            <Box display='flex' justifyContent='space-between' minWidth='300px'>
              <NameCellContent>
                {/*{mapIcons(chainId)}*/}
                <SmartVaultNameTableTypography variant='body2'>
                  {name}
                </SmartVaultNameTableTypography>
              </NameCellContent>
            </Box>
          </TableCell>
          <TableCell align='right'>
            <Typography variant='body2'>
              {formatNumber(parseFloat(apy), 2)} %
            </Typography>
          </TableCell>
          <TableCell align='right'>
            <Typography variant='body2'>
              {tvr === 'N/A' ? tvr : `$ ${formatUSD(tvr)}`}
            </Typography>
          </TableCell>
          <TableCell align='right'>
            {formatNumber(riskScore, 1)}/10 <br />
            <Typography variant='tableHelper'>{riskModelName}</Typography>
          </TableCell>
          <TableCell>
            <Box display='flex' alignItems='center' gap='0.5rem'>
              {assetGroupTokens.map(({ address }, index) => {
                return (
                  <Box key={index} display='flex' alignItems='center'>
                    {mapAssetTokenIcon(chain, address)}
                    {assetConfig[address]
                      ? assetConfig[address].assetLabel
                      : NOT_AVILABLE}
                  </Box>
                )
              })}
            </Box>
          </TableCell>
          <TableCell align='center'>
            <Box>
              <Tooltip title={info} arrow placement='left'>
                <IconButton
                  sx={{ padding: '4px', opacity: info ? '1' : '0.4' }}
                  disableRipple
                  disabled={!info}
                >
                  <InfoIcon className='lg' sx={{ color: 'primary.main' }} />
                </IconButton>
              </Tooltip>
              {warning && (
                <Tooltip title={description} arrow placement='left'>
                  <IconButton sx={{ padding: '4px' }} disableRipple>
                    <WarningIcon
                      className='lg'
                      sx={{ color: 'primary.main' }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </TableCell>
        </TableRow>
      </Tooltip>
    </>
  )
}

export default memo(StrategiesTableRow)
