import { GuardConditions } from '@solidant/spool-v2-fe-lib'

import useLocale from '@/hooks/context/useLocale'

import DataLoading from '@/components/molecules/DataLoading'
import DataMissing from '@/components/molecules/DataMissing'
import SectionColumn from '@/components/molecules/SectionColumn'
import GuardConditionsTable from '@/components/organisms/SmartVaults/CollapsibleRow/DepositConditions/GuardConditionsTable'

interface GuardConditionsProps {
  guardConditions: GuardConditions[]
}

const GuardConditions: React.FC<GuardConditionsProps> = ({
  guardConditions,
}) => {
  const t = useLocale()

  return (
    <SectionColumn
      title={t(
        'components.organisms.smartVaults.collapsibleRow.guardConditions.sectionTitle'
      )}
      sx={{ flexGrow: 7, flexBasis: 0, gap: (theme) => theme.spacing(1) }}
      noPadding
    >
      {guardConditions ? (
        guardConditions.length &&
        !guardConditions.some((guardCondition) => {
          guardCondition === undefined
        }) &&
        !guardConditions.some(
          (guardCondition) => typeof guardCondition === 'undefined'
        ) ? (
          <GuardConditionsTable guardConditions={guardConditions} />
        ) : (
          <DataMissing text='Deposit Conditions Not Found' />
        )
      ) : (
        <DataLoading loadingText='Loading Deposit Conditions' />
      )}
    </SectionColumn>
  )
}

export default GuardConditions
