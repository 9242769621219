import { HeadCell } from '@/types/table'

export const strategiesHeadCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Strategies',
  },
  {
    id: 'apy',
    numeric: true,
    label: '7D APY',
    align: 'right',
    width: '200px',
  },
  {
    id: 'countOfVaults',
    numeric: true,
    label: 'Count of vaults',
    align: 'right',
    width: '200px',
  },
  {
    id: 'tvr',
    numeric: true,
    label: 'Strategy TVL',
    align: 'right',
  },
  {
    id: 'balanceUSD',
    numeric: true,
    label: 'Total Value Routed',
    align: 'right',
    width: '200px',
  },
  /*{
    id: 'auditor',
    numeric: false,
    label: 'Audited by',
    align: 'right',
  },*/
]

export const strategyVaultsHeadCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Smart vault',
  },
  {
    id: 'adjustedApy',
    numeric: true,
    label: 'Base APY',
    align: 'right',
  },

  {
    id: 'tvrAllocatedToStrategy',
    numeric: true,
    label: 'TVR',
    align: 'right',
  },
]
